import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../../components/ExternalLink';
import LeadParagraph from '../../../components/LeadParagraph';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "favicon",
      "style": {
        "position": "relative"
      }
    }}>{`Favicon`}<a parentName="h1" {...{
        "href": "#favicon",
        "aria-label": "favicon permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  The City of Helsinki favicon is used in digital services to identify the site window or tab.
    </LeadParagraph>
    <p>{`The favicon consists of the text `}<em parentName="p">{`Helsinki`}</em>{` framed by the shape derived from the city crest. The favicon must be used in accordance with the `}<ExternalLink href="https://brand.hel.fi/en/" mdxType="ExternalLink">{`Visual Identity Guidelines`}</ExternalLink>{`.`}</p>
    <p><strong parentName="p">{`Do not attempt to recreate the favicon!`}</strong>{`
Always use the appropriate original file. `}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` The City of Helsinki logo is a registered trademark.`}</p>
    <h2 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h2" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Official City of Helsinki services must always show the City of Helsinki favicon: `}</p>
    <ul>
      <li parentName="ul">{`Sitewide, in the browser website title`}</li>
      <li parentName="ul">{`Use the same favicon variant consistently`}</li>
    </ul>
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "contrast",
      "style": {
        "position": "relative"
      }
    }}>{`Contrast`}<a parentName="h3" {...{
        "href": "#contrast",
        "aria-label": "contrast permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The Helsinki framed favicon is primarily used in black, white, or with a transparent background depending on the circumstance.`}</p>
    <ul>
      <li parentName="ul">{`Check that the colour contrast of the favicon and its background is compliant with `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#contrast-minimum" mdxType="ExternalLink">{`AA Level contrast ratios`}</ExternalLink>{`.`}</li>
      <li parentName="ul">{`For more information, see the `}<ExternalLink href="https://brand.hel.fi/en/colours/" mdxType="ExternalLink">{`Visual Identity Guidelines - Colours`}</ExternalLink>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "variants",
      "style": {
        "position": "relative"
      }
    }}>{`Variants`}<a parentName="h2" {...{
        "href": "#variants",
        "aria-label": "variants permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`There are many variants available in HDS. They all serve a very specific purpose.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Dark favicon package`}</strong>{` White favicon assets are used on dark backgrounds.`}</li>
      <li parentName="ul"><strong parentName="li">{`Light favicon package`}</strong>{` Black favicon assets are used on light backgrounds.`}</li>
      <li parentName="ul"><strong parentName="li">{`Dark transparent favicon package`}</strong>{` White favicon assets are used on transparent or multicoloured backgrounds.`}</li>
      <li parentName="ul"><strong parentName="li">{`Light transparent favicon package`}</strong>{` Black favicon assets are used on transparent or multicoloured backgrounds.`}</li>
    </ul>
    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}>{`Installation`}<a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ol>
      <li parentName="ol">{`Use the Github download link provided below to download the HDS Favicon package. `}</li>
      <li parentName="ol">{`Unzip the archive and copy the needed favicon package contents to the root directory of your website.`}</li>
      <li parentName="ol">{`Extract the contents and copy the following link tags and paste them into the `}<inlineCode parentName="li">{`HEAD`}</inlineCode>{` of your HTML as needed.`}</li>
    </ol>
    <p><strong parentName="p">{`For example:`}</strong></p>
    <p><strong parentName="p">{`Note`}</strong>{` All favicons and app icons have a specific purpose, but you are by no means required to use all of them.`}</p>
    <h3 {...{
      "id": "download",
      "style": {
        "position": "relative"
      }
    }}>{`Download`}<a parentName="h3" {...{
        "href": "#download",
        "aria-label": "download permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The favicon for the City of Helsinki digital services is a minimised version of the Helsinki logo. Various favicons are available as a downloadable ZIP package.`}</p>
    <p>{`You can `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/latest/download/hds-favicon-kit.zip" mdxType="ExternalLink">{`download the latest HDS Favicon kit from the HDS GitHub repository`}</ExternalLink>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      